<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-21 20:12:30
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-05 03:19:03
-->

<template>
  <div class="">
    <a-input-group compact>
      <a-input style="width: 80%;" v-model="name" disabled placeholder="请查询商品"></a-input>
      <a-button style="width: 20%;margin: 0;" @click="toSearch" icon="search"></a-button>
    </a-input-group>

    <a-modal :maskClosable="false" title="查找商品" style="top: 8px;" width="80%" v-model="visible" :footer="null" @cancel="closeModal">
      <a-row>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 25%; display: flex; align-items: center">物料编号：</div>
            <a-input v-model.trim="queryParam.goodsCode" placeholder="物料编号" style="width: 70%" :max-length="50"></a-input>
          </div>
        </a-col>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 25%; display: flex; align-items: center">编码名称：</div>
              <a-input v-model.trim="queryParam.cusCodeName"  style="width: 70%" placeholder="请输入经销商编码名称" :max-length="50"></a-input>
          </div>
        </a-col>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 25%; display: flex; align-items: center">图号：</div>
              <a-input v-model.trim="queryParam.chartCount" style="width: 70%" placeholder="请输入图号" :max-length="50"></a-input>
          </div>
        </a-col>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 25%; display: flex; align-items: center">OE码：</div>
              <a-input v-model.trim="queryParam.oeCode" style="width: 70%" placeholder="请输入OE码" :max-length="50"></a-input>
          </div>
        </a-col>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 25%; display: flex; align-items: center">排量：</div>
            <a-select v-model="carDisplac" style="width: 70%" placeholder="请选择" @change="handleChange">
              <a-select-option :value="item.id" style="width: 70%" v-for="(item, index) in displacement" :key="index">{{item.title}}</a-select-option>
            </a-select>
          </div>
        </a-col>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 25%; display: flex; align-items: center">商品名称：</div>
            <a-input v-model.trim="queryParam.productName" style="width: 70%" placeholder="请输入商品名称" :max-length="50"></a-input>
          </div>
        </a-col>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 25%; display: flex; align-items: center">商品分类：</div>
            <a-cascader :options="categoryList" style="width: 70%" v-model="categoryValue" :field-names="{ label: 'name', value: 'id', children: 'children' }" placeholder="请选择" @change="onCategoryChange" />
          </div>
        </a-col>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 25%; display: flex; align-items: center">车信息：</div>
              <a-cascader :options="options" style="width: 70%" v-model="carinfo" :field-names="{ label: 'name', value: 'id', children: 'children' }" placeholder="请选择" @change="onSelectChange" />
          </div>
        </a-col>
        <a-col :md="6">
          <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
          <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
        </a-col>
      </a-row>
      <div class="table">
        <a-table size="small"  { x: 1800 }" :data-source="tableData" bordered :rowKey="(record) => record.id" :pagination="page" @change="p=>{page=p;toSearch(2)}" :customRow="changeTableRow">
          <a-table-column title="商品名称" data-index="productName" :width="220" :ellipsis="true" align="left" fixed='left'>
            <template slot-scope="text">
                <a-tooltip> <template slot="title"> {{text}} </template> {{text}} </a-tooltip>
            </template>
          </a-table-column>
          <a-table-column title="图片" data-index="goodsImage" :width="180" :ellipsis="true" align="center">
            <template slot-scope="text">
              <ZoomMedia style="height: 20px;" :value="text"></ZoomMedia>
            </template>
          </a-table-column>
          <a-table-column title="规格" data-index="specStr" :width="150" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="分类" data-index="categoryName" :width="200" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="车品牌" data-index="carBrand" :width="120" :ellipsis="true" align="center">
          </a-table-column>
          <a-table-column title="车系" data-index="carSeries" :width="120" :ellipsis="true" align="center">
          </a-table-column>
          <a-table-column title="车型" data-index="carModel" :width="200" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="排量" data-index="carDisplacement" :width="120" :ellipsis="true" align="center">
          </a-table-column>
          <a-table-column title="图号" data-index="chartCount" :width="120" :ellipsis="true" align="center">
          </a-table-column>
          <a-table-column title="OE码" data-index="oeCode" :width="120" :ellipsis="true" align="center">
          </a-table-column>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
  const qs = require('qs')
  export default {
    data() {
      return {
        visible: false,
        name: '',
        name2: '',
        tableData: [],
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
          size: 'small'
        },
        queryParam: {},
        options: [],
        carinfo: [],
        categoryList: [],
        categoryValue: [],
        displacement: [],
        carDisplac: []
      }
    },
    props: ['value', 'customerid'],
    watch: {
      value(newVal) {
        this.name = newVal
      }
    },
    methods: {
      closeModal() {
        this.name2 = ''
      },
      toSearch(type) {
        if(!this.customerid){
          return this.$message.warning("请先选择经销商")
        }
        if(type == 1) {
          this.queryParam = {}
          this.carinfo = []
          this.categoryValue = []
          this.carDisplac = []
        }
        this.visible = true
        const postData = Object.assign({}, {
          name: this.name2,
          pageNumber: type == 2 ? this.page.current : this.page.current = 1,
          pageSize: this.page.pageSize,
          cusId: this.customerid,
          ...this.queryParam
        })
        this.axios.get(`/api/customer/customer/customerPricePolicy/listByCustomer?${qs.stringify(postData)}`).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        }).catch(err => {})
      },
      changeTable(pagination, filters, sorter) {
        this.page = pagination
        this.toSearch(2)
      },
      changeTableRow(record) {
        return {
          on: {
            dblclick: e => {
              this.visible = false
              this.$emit('update:value', record.customerName)
              this.$emit('select', record)
              this.$emit('change')
            }
          }
        }
      },
      onSelectChange(value) {
        this.queryParam.carBrandId = value ? value[0] : ''
        this.queryParam.carSeriesId = value ? value[1] : ''
        this.queryParam.carModelId = value ? value[2] : ''
      },
      onCategoryChange(value) {
        var reg = new RegExp(",","g")
        this.queryParam.categoryIdStr = value.map(i => {return i}).toString().replace(reg, '_')
      },
      handleChange(value) {
        this.queryParam.carDisplacementId = value
      }
    },
    created() {
      if (this.value) {
        this.name = this.value
      }
      this.axios.get('/api/product/car/carBaseInfo/list?flagEnable=true').then(res => {
        this.options = res.body[0] ? res.body[0].children : []
      })
      this.axios.get('/api/product/category/list?flagEnable=true').then(res => {
        this.categoryList = res.body[0] ? res.body[0].children : []
      })
      this.axios.get('/api/base/system/dictionary/selectByCode/排量').then(res => {
        this.displacement = res.body
      })
    }
  }
</script>

<style lang="scss" scoped>
</style>
